import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ResponsiveTitle from "../../../components/ResponsiveTitle";
import AddressBook from "./AddressBook";
import AddressForm from "./AddressForm";

const AddressManager = () => {
  const { t } = useTranslation();
  const addresses = useSelector((state) => state.addresses.addressInfo);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveTitle>{t("address.addressManager")}</ResponsiveTitle>
      <Row justify="center" gutter={[40, 40]}>
        <Col md={12}>
          <ResponsiveTitle level={3}>{t("address.addAddress")}</ResponsiveTitle>
          <AddressForm />
        </Col>
        {addresses.length > 0 && (
          <Col md={12}>
            <AddressBook />
          </Col>
        )}
      </Row>
    </Space>
  );
};

export default AddressManager;
