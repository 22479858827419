import Space from "antd/lib/space";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ResponsiveTitle from "../../../components/ResponsiveTitle";
import AddressCard from "./AddressCard";

const AddressBook = () => {
  const { t } = useTranslation();
  const addresses = useSelector((state) => {
    return state.addresses.addressInfo;
  });

  if (!addresses.length) return null;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveTitle level={3}>{t("address.addressBook")}</ResponsiveTitle>
      {addresses.map((address) => {
        return <AddressCard address={address} key={address.name} />;
      })}
    </Space>
  );
};

export default AddressBook;
